'use client';

import { useEffect } from 'react';
import analytics from '@/lib/analytics';
import SpringAmazeIconBlackText from '@/components/Icons/SpringAmazeIconBlackText';
import styles from './index.module.css';

type ErrorProps = {
  error?: Error;
  reset?: () => void;
};

export default function Error({ error, reset }: ErrorProps) {
  useEffect(() => {
    analytics.track('error_viewed', { errorTitle: error?.message });
  });

  const isDev = process.env.NODE_ENV === 'development';

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <h1 className={styles.boldHeaderText}>Sorry, something went wrong.</h1>
        <p className={styles.subtext}>Please try coming back later.</p>
        {isDev && error && <p className={styles.devErrorText}>{error.message}</p>}
        {isDev && reset && (
          <button type='button' onClick={reset} className={styles.retryButton}>
            Retry
          </button>
        )}
        <div className={styles.footer}>
          <span className={styles.footerText}>Powered by</span>
          <div className={styles.footerLogo}>
            <SpringAmazeIconBlackText />
          </div>
        </div>
      </div>
    </div>
  );
}
