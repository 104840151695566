'use client';

import { TrackJS } from 'trackjs';

import { TRACKJS_APPLICATION, TRACKJS_TOKEN } from '@/constants';
import Error from '@/components/Error';

// TODO: revisit ssr trackjs in the app router
if (TRACKJS_TOKEN && TrackJS && !TrackJS.isInstalled()) {
  TrackJS.install({
    token: TRACKJS_TOKEN,
    application: TRACKJS_APPLICATION,
  });
}

export default function RootError({ error, reset }: { error: Error; reset: () => void }) {
  return <Error error={error} reset={reset} />;
}
